import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ReactDOMServer from "react-dom/server";
import { toast } from "react-toastify";

import { WelcomeEmailContent } from "./WelcomeEmailContent";
import { generateDocument } from "../fileHandlers/docxTemplater";

export const SendRegistrationEmail = async (userData, firestore, storage) => {
  const mailCollection = collection(firestore, "mail");

  const emailAddresses = userData.relativeEmail
    ? [userData.email, userData.relativeEmail]
    : [userData.email];
  const name = userData.displayName || "Не вказано";

  const emailHtml = ReactDOMServer.renderToStaticMarkup(
    <WelcomeEmailContent name={name} />
  );

  // ЦЕ НЕ ЗНАДОБИЛОСЬ
  //   const emailText = `
  //     Шановний ${name}, вітаємо зі вступом до INTERNATIONAL ACADEMY MBO.
  // Тепер Ви можете зайти в особистий кабінет та обрати вподобані курси.
  // Всі навчальні матеріали, розміщені на нашому сайті,  знаходиться в вільному доступі та необмежені в часі користування.
  // Додатково (але не обов'язково) ви можете складати тести, екзамени та отримати диплом про закінчення навчання. Це дасть вам змогу пройти стажування в європейських компаніях, та швидше реалізуватись як спеціаліст.
  // Реєстрація для здачі тестів та екзаменів потребує сплати адміністративного збору, який складає 87 євро.
  // Рахунок (Invoice) про оплату адміністративного збору також надіслано на вашу електронну пошту.
  // Якщо ви не плануєте здавати екзамени та отримувати диплом, платіж згідно Invoice, здійснювати не потрібно.
  // З побажанням успіхів,
  // Secretaris-Generaal            Mark Beijen
  //   `;

  // ГЕНЕРУЄМО ІНВОЙС

  generateDocument(userData, "invoice", firestore, async (blob) => {
    console.log("Generated blob received in callback:", blob);
    const newDate = new Date().toISOString().split("T")[0];
    const storageRef = ref(
      storage,
      `invoices/${userData.displayName}-${newDate}.docx`
    );
    try {
      await uploadBytes(storageRef, blob);
      const downloadURL = await getDownloadURL(storageRef);
      console.log("File uploaded and available at:", downloadURL);
      const emailData = {
        to: emailAddresses,
        message: {
          subject: "INTERNATIONAL ACADEMY MBO",
          // text: emailText,
          html: emailHtml,

          // ІНВОЙС ВИКЛ
          // не забудь поправити текст листа де там про інвойс написано

          // attachments: [
          //   {
          //     filename: "invoice.docx",
          //     path: downloadURL,
          //   },
          // ],
        },
      };
      console.log("Email data before sending:", emailData);
      await addDoc(mailCollection, emailData);
      console.log("Registration email has been added to the collection.");
      toast.success("Check your email");
    } catch (error) {
      console.error("Error adding registration email: ", error);
      toast.error("Error adding registration email.");
    }
  });
};

// export const SandDiscordMessage = async (userData, firestore) => {
//   const functions = require("firebase-functions");
//   const { Client, GatewayIntentBits } = require("discord.js");

//   const token = "7124";
//   const channelId = "1219252814090469459";

//   const client = new Client({
//     intents: [GatewayIntentBits.Guilds, GatewayIntentBits.GuildMessages],
//   });

//   // Функція для ініціалізації Discord клієнта
//   client.login(token);

//   exports.sendDiscordMessage = functions.https.onCall(async (data, context) => {
//     // Перевірка аутентифікації користувача
//     if (!context.auth) {
//       throw new functions.https.HttpsError(
//         "unauthenticated",
//         "The function must be called while authenticated."
//       );
//     }

//     try {
//       const channel = await client.channels.fetch(channelId);
//       await channel.send(
//         `Нова реєстрація: ${data.firstName} ${data.lastName}, Email: ${data.email}`
//       );
//       return { success: true };
//     } catch (error) {
//       console.error("Discord Error:", error);
//       throw new functions.https.HttpsError(
//         "internal",
//         "Unable to send message to Discord."
//       );
//     }
//   });
// };

/// код ватсап
// export const SaveUserRegistrationData = async (userData, firestore) => {
//   const whatsappCollection = collection(firestore, "whatsapp-msg");

//   try {
//     await addDoc(whatsappCollection, {
//       from: "whatsapp:+14155238886",
//       to: "whatsapp:+380977599895",
//       // body: `Нова реєстрація: ${userData.firstName} ${userData.lastName}, Email: ${userData.email}, Телефон: ${userData.tel}`,
//       body: `Нова реєстрація`,
//     });

//     console.log("Реєстраційні дані відправлені для нотифікації в WhatsApp");
//   } catch (error) {
//     console.error("Помилка при збереженні реєстраційних даних:", error);
//   }
// };
