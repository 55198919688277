export const WelcomeEmailContent = ({ name }) => {
  return (
    <div>
      <html>
        <body
          style={{
            fontFamily: "Arial, sans-serif",
            lineHeight: "1.6",
            color: "black",
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "10px",
            margin: "0",
          }}
        >
          <div style={{ width: "700px", margin: "auto", color: "black" }}>
            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
                color: "black",
              }}
            >
              <img
                src="https://myplaylist2023.s3.eu-north-1.amazonaws.com/android-chrome-192x192.png"
                height="70px"
                width="70px"
                alt="Company Logo"
              />
              <h1
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  margin: "0",
                  color: "black",
                }}
              >
                INTERNATIONAL ACADEMY MBO
              </h1>
            </div>
            <hr
              style={{
                border: "none",
                borderBottom: "2px solid #ddd",
                marginBottom: "20px",
              }}
            />
            <h2
              style={{
                textAlign: "center",
                fontSize: "20px",
                marginBottom: "20px",
                color: "black",
              }}
            >
              Шановний {name}, вітаємо зі вступом до
              <br /> INTERNATIONAL ACADEMY MBO.
            </h2>
            <p
              style={{
                marginBottom: "5px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Тепер Ви можете зайти в особистий кабінет та обрати вподобані
              курси.
            </p>
            <p
              style={{
                marginBottom: "5px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Всі навчальні матеріали, розміщені на нашому сайті, знаходиться в
              вільному доступі та необмежені в часі користування.
            </p>
            <p
              style={{
                marginBottom: "5px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Додатково (але не обов'язково) ви можете складати тести, екзамени
              та отримати диплом про закінчення навчання. Це дасть вам змогу
              пройти стажування в європейських компаніях, та швидше
              реалізуватись як спеціаліст.
            </p>

            <p
              style={{
                marginBottom: "5px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Більше інформації про навчання ви можете отримати в нашому
              Телеграм - чаті за посиланням{" "}
              <strong>https://t.me/+Hy8uWITdllxhZWQ6</strong>
            </p>

            {/*  <p
              style={{
                marginBottom: "5px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Реєстрація для здачі тестів та екзаменів потребує сплати
              адміністративного збору, який складає 87 євро .
            </p>

            <p
              style={{
                marginBottom: "5px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Рахунок (Invoice) про оплату адміністративного збору буде
              надісланий протягом місяця на вашу електронну пошту.
            </p> 

            <p
              style={{
                marginBottom: "5px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Якщо ви не плануєте здавати екзамени та отримувати диплом, платіж
              згідно Invoice, здійснювати не потрібно.
            </p>*/}

            <table style={{ width: "100%", marginTop: "30px" }}>
              <tbody>
                <tr>
                  <td style={{ width: "50%", color: "black" }}>
                    <p style={{ fontWeight: "bold", color: "black" }}>
                      З побажанням успіхів,
                      <br />
                      Керівник адміністрації
                      <br />
                      Mark Beijen
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </body>
      </html>
    </div>
  );
};
