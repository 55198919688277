import React, { useState, useEffect, useContext, useCallback } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../utils/i18n";

import { Context } from "../../index";
import { LoginModal } from "../Modals/auth/auth";
import { openHandler, closeHandler } from "../../utils/dom/open-close-modal";
import { useScrollPosition } from "../../utils/dom/scroll";
import { SubHeaderComponent } from "./subHeader";
import { Burger } from "./burger";

import BackImg from "../../lib/logo-icons/backArrow2.png";
import loginImg from "../../lib/login-img/login3.png";
import logoImg from "../../lib/logo-icons/logo2.png";

import enFlag from "../../lib/logo-icons/icon-en.png";
import deFlag from "../../lib/logo-icons/icon-de2.png";
import nlFlag from "../../lib/logo-icons/icon-nl3.png";
import uaFlag from "../../lib/logo-icons/icon-ua3.png";
import ruFlag from "../../lib/logo-icons/icon-ru2.png";
import "./headerStyle.scss";

const flags = [
  { lang: "en", img: enFlag, label: "English" },
  { lang: "de", img: deFlag, label: "Deutsch" },
  { lang: "nl", img: nlFlag, label: "Nederlands" },
  { lang: "ua", img: uaFlag, label: "Українська" },
  { lang: "ru", img: ruFlag, label: "Русский" },
];

export const Header = ({ mod, type }) => {
  const navigate = useNavigate();
  const headerResize = useScrollPosition();
  const [showTitle, setShowTitle] = useState(true);
  const [isLesson, setIsLesson] = useState(false);
  const { t } = useTranslation();
  // const homeMods = [
  // "cabinet",
  // "about_us",
  // "news",
  // "faq",
  // "integration_course",
  // "lang_dutch_course",
  // "pro_restaurant",
  // "pro_logistics",
  // "pro_retail",
  // "pro_hotel",
  // "pro_entrepreneurship",
  // "pro_food_industry",
  // ];

  useEffect(() => {
    if (type === "page") {
      setShowTitle(false);
    } else if (type === "lesson") {
      setIsLesson(true);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLogoClick = () => {
    navigate("/");
    scrollToTop();
  };

  return (
    <section className="headers">
      <div
        className={`header ${
          headerResize ? "header--short" : "header--normal"
        }`}
      >
        <img
          className="header__logo"
          onClick={handleLogoClick}
          src={logoImg}
          alt="MBO-logo"
        />

        {mod !== "main" && (
          <section className="headerNavBar">
            <img
              className="header__back-arrow"
              onClick={() => {
                navigate(isLesson ? "/cabinet" : "/");
              }}
              src={BackImg}
              alt="backBtn"
            />

            {showTitle && <div className="headerNavBar__title">{t(mod)}</div>}
          </section>
        )}

        <AuthComponent headerResize={headerResize} mod={mod} />

        <button
          className={`scrollBtn ${headerResize ? "visible" : "hidden"}`}
          onClick={scrollToTop}
        >
          <span className={`scrollBtn__arrow`}></span>
        </button>
      </div>

      {mod === "main" && <SubHeaderComponent />}
    </section>
  );
};

const AuthComponent = ({ headerResize, mod }) => {
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);
  const { auth } = useContext(Context);
  const [user] = useAuthState(auth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({
    login: false,
    acc: false,
    adm: false,
  });

  const handleOpenModal = (modalType) => {
    openHandler();
    setShowModal((prev) => ({
      ...prev,
      [modalType]: true,
    }));
    if (modalType !== "login") {
      setShowAvatarMenu(false);
    }
  };

  const handleCloseModal = (modalType) => {
    closeHandler();
    setShowModal((prev) => ({
      ...prev,
      [modalType]: false,
    }));
  };

  const handleMenubtnClick = () => {
    setShowAvatarMenu(false);
    navigate("/cabinet");
  };
  const handleMouseEnter = () => {
    setShowAvatarMenu(true);
  };
  const handleMouseLeave = () => {
    setShowAvatarMenu(false);
  };
  const handleLogout = () => {
    auth.signOut();
    sessionStorage.clear();
    setShowAvatarMenu(false);
    navigate("/");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section className="header__container">
      <SwitchBtn headerResize={headerResize} />

      <div
        className="header__wrap"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setShowAvatarMenu(!showAvatarMenu)}
      >
        {user ? (
          <img className="header__logo" src={user.photoURL} alt="Avatar" />
        ) : (
          <img
            className="header__logo header__logo--transparent"
            onClick={() => handleOpenModal("login")}
            src={loginImg}
            alt="MBO-login"
            id="login-btn"
          />
        )}
      </div>

      {user && showAvatarMenu && (
        <div
          className="header__menu"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          id="login-btn"
        >
          <button className="header__btn2" onClick={handleMenubtnClick}>
            {t("cabinet")}
          </button>
          <button className="header__btn2" onClick={handleLogout}>
            {t("logout")}
          </button>
        </div>
      )}

      {mod === "main" && <Burger />}

      <LoginModal
        showLoginModal={showModal.login}
        setShowLoginModal={() => handleCloseModal("login")}
      />
    </section>
  );
};

function SwitchBtn() {
  const { i18n } = useTranslation();
  const [currentFlag, setCurrentFlag] = useState(0);
  const [showLangMenu, setShowLangMenu] = useState(false);

  useEffect(() => {
    // Отримання збереженої мови з localStorage
    const savedLang = localStorage.getItem("lang") || "en";
    const foundIndex = flags.findIndex((flag) => flag.lang === savedLang);
    if (foundIndex !== -1) {
      setCurrentFlag(foundIndex);
      i18n.changeLanguage(savedLang);
    }
  }, [i18n]);

  const changeLanguage = (index) => {
    setCurrentFlag(index);
    const nextLang = flags[index].lang;
    i18n.changeLanguage(nextLang);
    localStorage.setItem("lang", nextLang); // Збереження обраної мови
    setShowLangMenu(false); // Закрити меню після вибору мови
  };

  return (
    <div
      className="header__wrap"
      onClick={() => setShowLangMenu(!showLangMenu)}
      onMouseEnter={() => {
        setShowLangMenu(true);
      }}
      onMouseLeave={() => {
        setShowLangMenu(false);
      }}
    >
      <div className="header__flag">
        <img
          className="header__flag-img"
          src={flags[currentFlag].img}
          alt="Current flag"
          style={{ transform: `translateX(0%)` }}
        />
      </div>
      {showLangMenu && (
        <div
          className={`header__menu ${showLangMenu && "header__menu--large"}`}
        >
          {flags.map((flag, index) => (
            <div
              key={index}
              className={`header__menu-flag ${
                index === currentFlag && "header__btn2"
              }`}
              onClick={() => changeLanguage(index)}
            >
              {flag.label}
              <span className="header__menu-span"></span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
