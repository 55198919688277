const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const openHandler = () => {
  document.body.style.overflow = "hidden"; // блокуєм прокрутку
  if (!isMobile) {
    document.querySelector(".scrollBtn").style.right = `27px`;
    document.querySelector(".headers").style.right = `7px`;
    document.body.style.borderRight = "7px solid rgba(0, 0, 0, 0.9)";
  }
};

export const closeHandler = () => {
  document.body.style.overflow = ""; // розблоковуєм прокрутку
  if (!isMobile) {
    document.querySelector(".scrollBtn").style.right = `20px`;
    document.querySelector(".headers").style.right = ``;
    document.body.style.borderRight = "";
  }
};
