import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useScrollPosition } from "../../utils/dom/scroll";

import heyDutch from "../../lib/logo-icons/HeyDutch-300x87.png";

import "./subHeaderStyle.scss";

export const SubHeaderComponent = () => {
  const [activeItem, setActiveItem] = useState(null);
  const [modalStyle, setModalStyle] = useState({});
  const headerResize = useScrollPosition();
  const navigate = useNavigate();
  const itemRefs = useRef([]);
  const { t, i18n } = useTranslation();

  const handleVideoLinkClick = () => {
    const videoLink =
      i18n.language === "ru"
        ? "https://www.youtube.com/watch?v=fA-UNFta1Yo"
        : "https://www.youtube.com/watch?v=M3ZDr41l7M0";
    window.open(videoLink, "_blank");
  };

  const handleOpenCourse = (courseName) => {
    navigate(`/${courseName}`);
  };

  const handleContainerMouseLeave = (e) => {
    const isInsideSubHeaderItem =
      e.relatedTarget &&
      e.relatedTarget.classList &&
      e.relatedTarget.classList.contains("subHeader__item");
    const isInsideModalContainer =
      e.relatedTarget &&
      e.relatedTarget.classList &&
      e.relatedTarget.classList.contains("subHeaderModal");
    if (!isInsideSubHeaderItem && !isInsideModalContainer) {
      setActiveItem(null);
    }
  };

  const handleMouseEnter = (item, index) => {
    setActiveItem(item.modalName);
    const rect = itemRefs.current[index].getBoundingClientRect();
    let leftPosition;
    let rightPosition;
    let transform;

    switch (index) {
      case 0:
        leftPosition = `${rect.left - 25}px`;
        break;
      case 1:
        leftPosition = `${rect.left + rect.width * 0.2 - 45}px`;
        break;
      case 2:
        leftPosition = `${rect.left + rect.width * 0.4 - 70}px`;
        break;
      case 3:
        leftPosition = `${rect.left + rect.width * 0.5}px`;
        transform = "translateX(-50%)";
        break;
      case 4:
        rightPosition = `${
          window.innerWidth - rect.right + rect.width * 0.2 - 55
        }px`;
        break;
      case 5:
        rightPosition = `${window.innerWidth - rect.right - 30}px`;
        break;
      default:
        leftPosition = `${rect.left}px`;
    }

    setModalStyle({
      left: leftPosition,
      right: rightPosition,
      top: `100%`,
      transform: transform,
    });
  };

  const items = [
    { name: t("courses"), modalName: "Courses" },
    { name: t("about_us"), modalName: "about_us" },
    { name: t("news"), modalName: "News" },
    { name: t("faq"), modalName: "Faq" },
    { name: t("instruction"), modalName: "instruction" },
    { name: t("our_partners"), modalName: "our_partners" },
    // { name: t("contacts"), modalName: "Contacts" },
  ];

  const coursesConfig = [
    {
      category: "language_and_integration_courses",
      courses: [
        { id: "1", name: "lang_dutch_course" },
        { id: "2", name: "integration_course" },
      ],
    },
    {
      category: "professional_courses",
      courses: [
        { id: "3", name: "pro_restaurant" },
        { id: "4", name: "pro_logistics" },
        { id: "5", name: "pro_retail" },
        { id: "6", name: "pro_hotel" },
        { id: "7", name: "pro_entrepreneurship" },
        { id: "8", name: "pro_food_industry" },
      ],
    },
  ];

  return (
    <section
      className={`subHeader ${
        headerResize ? "subHeader--hidden" : "subHeader--visible"
      }`}
    >
      <div className="subHeader__container">
        {items.map((item, index) => (
          <div
            key={item.modalName}
            onMouseEnter={() => handleMouseEnter(item, index)}
            onMouseLeave={handleContainerMouseLeave}
            ref={(el) => (itemRefs.current[index] = el)}
          >
            <p className="subHeader__item">{item.name}</p>
          </div>
        ))}
      </div>

      {activeItem && (
        <section
          className="subHeaderModal"
          style={modalStyle}
          onMouseLeave={handleContainerMouseLeave}
        >
          {activeItem === "Courses" && (
            <div className="subHeaderModal__container subHeaderModal__container--150">
              {coursesConfig.map((category) => (
                <div className={`subHeaderModal__col`} key={category.category}>
                  <h1 className="subHeaderModal__title">
                    {t(category.category)}
                  </h1>
                  <div className="subHeaderModal__col">
                    {category.courses.map((course) => (
                      <li
                        key={course.id}
                        className="subHeaderModal__item"
                        onClick={() => handleOpenCourse(course.name)}
                      >
                        {t(course.name)}
                      </li>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {activeItem === "about_us" && (
            <div className="subHeaderModal__container subHeaderModal__container--80">
              <p className="subHeaderModal__text">
                {t("open_to_everyone")}{" "}
                <span
                  className="subHeaderModal__btn"
                  onClick={() => navigate(`/about_us`)}
                >
                  {t("learn_more")}
                </span>
              </p>
            </div>
          )}

          {activeItem === "News" && (
            <div className="subHeaderModal__container subHeaderModal__container--80">
              <p className="subHeaderModal__text">
                {t("value_of_education_and_career_connection")}{" "}
                <span
                  className="subHeaderModal__btn"
                  onClick={() => navigate(`/news`)}
                >
                  {t("learn_more")}
                </span>
              </p>
            </div>
          )}

          {activeItem === "Faq" && (
            <div className="subHeaderModal__container subHeaderModal__container--80">
              <p className="subHeaderModal__text">
                {t("age_limit")} {t("study_management_hotel")}{" "}
                {t("entrance_exams_required")}{" "}
                <span
                  className="subHeaderModal__btn"
                  onClick={() => navigate(`/faq`)}
                >
                  {t("learn_more")}
                </span>
              </p>
            </div>
          )}

          {activeItem === "instruction" && (
            <div className="subHeaderModal__container subHeaderModal__container--60">
              <p className="subHeaderModal__text">
                {t("watch_youtube_video")}{" "}
                <span
                  className="subHeaderModal__btn"
                  onClick={handleVideoLinkClick}
                >
                  {t("watch_video")}
                  {"..."}
                </span>
              </p>
            </div>
          )}

          {activeItem === "our_partners" && (
            <div className="subHeaderModal__container subHeaderModal__container--130">
              <div
                className="aboutUs__linkContainer"
                onClick={() =>
                  window.open(
                    "https://www.heydutch.com/",
                    "_blank",
                    "noopener noreferrer"
                  )
                }
              >
                <img
                  className="aboutUs__linkImg"
                  src={heyDutch}
                  alt="heyDutch img"
                />

                <div>
                  <p className="aboutUs__linkText">
                    {t("our_leading_partner")}.
                  </p>
                  <p className="aboutUs__linkText2">https://www.heydutch.com</p>
                </div>
              </div>
            </div>
          )}

          {activeItem === "Contacts" && (
            <div className="subHeaderModal__container subHeaderModal__container--contacts">
              <ContactsComponent />
              {/* <SocialsComponent /> */}
            </div>
          )}
        </section>
      )}
    </section>
  );
};

const ContactsComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="subHeaderModal__col">
      {/* <p className="subHeaderModal__title">{t("contacts")}</p> */}

      <div className="subHeaderModal__col-left">
        <div className="subHeaderModal__row-icon">
          {/* <img
            className="subHeaderModal__icon"
            src="https://cdn-icons-png.freepik.com/512/386/386220.png"
            alt=""
          /> */}
          {/* <p className="subHeaderModal__text">inacademyMBO@gmail.com</p> */}

          <a
            href="mailto:inacademyMBO.post@gmail.com"
            className="subHeaderModal__btn subHeaderModal__btn--link"
          >
            inacademyMBO.post@gmail.com
          </a>
        </div>
        {/* <div className="subHeaderModal__row-icon">
          <img
            className="subHeaderModal__icon"
            src="https://cdn-icons-png.flaticon.com/512/725/725624.png"
            alt=""
          />
          <p className="subHeaderModal__text">+31 6 457-83-229</p>
        </div> */}
      </div>
    </div>
  );
};

const SocialsComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="subHeaderModal__col">
      <p className="subHeaderModal__title">{t("social_media")}</p>
      <div className="subHeaderModal__row">
        <a href="https://www.facebook.com/groups/390578963845505">
          <img
            className="subHeaderModal__icon"
            src="https://cdn-icons-png.flaticon.com/512/174/174848.png"
            alt="img-facebook"
          />
        </a>

        <a href="https://t.me/+Hy8uWITdllxhZWQ6">
          <img
            className="subHeaderModal__icon"
            src="https://cdn-icons-png.flaticon.com/512/2111/2111646.png"
            alt="img-telegram"
          />
        </a>
      </div>
    </div>
  );
};
